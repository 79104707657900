<template xmlns="http://www.w3.org/1999/html">
  <div class="content ">


    <article
      class="row"
      :style="{
        background: ` ${this.primaryColor}`,
      }"    >

      <section class="text-center col-lg-8 col-sm-12">

        <HeadImage :image="dynamicContent.metadata.image.imgix_url" align="text-center"
                         clamp
                         title="TULUM TODAY"
                         text-transform="uppercase"
                         :color="this.secondaryColor"
                         caption="Your Daily Guide"></HeadImage>
        <hr>
        <div class="card text-center">
          <p>

            Wondering what's on in <strong>Tulum Today</strong>?
            <br>Check out the updated listings of Tulum events below.
          </p>
          <br>
          <img v-lazy="this.astroImg" height="40px" width="40px" class="centered">
          <br>
          <RsvpViaDialog

                         :club-config="{ color: this.primaryColor,primaryColor: this.primaryColor,
                         secondaryColor: this.secondaryColor }"></RsvpViaDialog>


        </div>


<!--        <h4>Discover the true Tulum: experience it at its fullest!</h4>-->

        <div
          class="text-left"
          :style="{ background: `linear-gradient(${this.primaryColor}, black)` }"

          style="
            color: #cacaca !important;
            margin-bottom: 1px;
            margin-top: 3px;
            padding-right: 20px;
            padding-left: 20px;
          "
        >

          <h3 style="font-size: 2rem;text-transform: capitalize" class="section-header"
          >      {{ getOrdinalDay()}} </h3>

          <div v-for="event in this.incomingParties"
               :style="{ background: `linear-gradient(${secondaryColor}, black)` }"

          >
            <div class="col-sm-12 "
                 :style="{ background: `linear-gradient(${secondaryColor}, black)` }"

            >
<!--              <DjBioCollection></DjBioCollection>-->

              <!--            <div v-html="event.content"></div>-->
              <div style="margin: 10px;padding: 15px" class="text-center">

              <MiniPartyCard
                :large="true"
                with-venue="true"
                v-if="event.metadata.magicEvent"
                :event="event"
                :club-config="{color: primaryColor,
                name: event.title,

                primaryColor: primaryColor}"
                class="card-animation-on-hover "

              ></MiniPartyCard>
              </div>
              <div class="card centered" style="background-color: #3e3b3b;padding: 50px"
                   v-if="!event.metadata.magicEvent"

              >
<!--                <MiniEventCard-->
<!--                  with-brand="true"-->
<!--                  without-brand="true"-->
<!--                  v-if="!event.metadata.magicEvent"-->
<!--                  v-bind:event="event"-->
<!--                  class="card-animation-on-hover "-->

<!--                ></MiniEventCard>-->
<!--                <CondensedEventCard :event="event"-->
<!--                           :with-lineup="true"-->
<!--                           v-if="!event.metadata.magicEvent"-->

<!--                ></CondensedEventCard>-->
                <div style="margin: 10px">
                  <IncomingParty
                    :card-title="false"
                    :logo="event.metadata.main_image.imgix_url"
                    :incoming-party="event"
                  ></IncomingParty>

                </div>
              </div>
            </div>
          </div>

          <SmartMenu :links="this.links" :logo="this.instagramTp" top-section-name="HAVE A QUESTION"
                     :primary-color="primaryColor">
            <div class="row">
              <div class="col-sm-12 text-center card">

                <img v-lazy="this.astroImg" height="50px" width="50px" class="centered">
                <p><strong>Feeling Lost?</strong></p>
                <p>
                  We know the scene inside out. Let's curate your perfect night.
                </p>
              </div>
            </div>
          </SmartMenu>
        </div>
      </section>

      <aside class="card col-sm-4 col-lg-4 d-none d-lg-inline">
        <h3 class="section-header">
          <img v-lazy="logo" style="border: 1px solid rgb(97, 234, 200);
          padding: 2px;
          border-radius: 400px" height="30px">
          Upcoming

        </h3>
        <HotThisWeek></HotThisWeek>
<!--        <DjBioCollection></DjBioCollection>-->
        <DjScheduleCollection size="10"></DjScheduleCollection>
        <SmartMenu :links="this.links" :logo="this.instagramTp" :primary-color="primaryColor">
          <p><strong>Feeling Lost?</strong></p>
          <hr>
          <p>
            We know the scene inside out. Let's curate your perfect night.
          </p>
          <hr>
        </SmartMenu>

      </aside>
    </article>
  </div>
</template>
<script>
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import { eventDateUtils } from '@/common/eventDateUtils';
import moment from 'moment';
import MiniPartyCard from '@/components/EventCard/MiniPartyCard';
import CondensedEventCard from '@/components/EventCard/CondensedEventCard';
import HotThisWeek from '@/pages/TulumDjSchedule/HotThisWeek';
import DjScheduleCollection from '@/components/Cards/DjScheduleCollection';
import RsvpViaDialog from '@/components/Reservations/RsvpViaDialog';
import HeadImage from '@/components/HeadImage/HeadImage';
import IncomingParty from '@/components/UpcomingEvents/IncomingParty';

export default {
  name: 'Today',
  props: ['slug'],
  mixins: [eventDateUtils],
  components: {
    IncomingParty,
    HeadImage,
    RsvpViaDialog,
    DjScheduleCollection,
    HotThisWeek,
    CondensedEventCard,
    MiniPartyCard,
    ResponsiveImage,
    SmartMenu
  },

  metaInfo() {
    return {
      title: `Tulum Events Today | Your Daily Guide | tulum.party`,
      // link: eventImage,
      meta: [
        {
          name: 'description',
          content: `Discover the top Tulum events today! Find Parties, DJ sets, Dinners, happenings, and more. Plan your day with our curated guide to what's happening in Tulum.`
        },
        {
          name: 'keywords',
          content: `tulum mexico events today`
        },
        {
          property: 'og:title',
          content: `Tulum Events Today | Your Daily Guide`
        },
        {
          property: 'og:url',
          content: `https://tulum.party/today`
        },
        {
          property: 'og:description',
          content: `Find the best Tulum events today! Discover live music, DJ sets, dinners, happenings, and more. Plan your day with our curated guide to what's happening in Tulum.`
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: this.mainImage
        }
      ]
    };
  },
  created() {
    this.$store.dispatch('getDynamicContent');
    this.$store.dispatch('getAllParties', {});
    this.$store.dispatch('getLinks', { slug: 'tulum-events-today' });
  },
  data: () => {
    return {
      primaryColor: '#1f1f1f',
      secondaryColor: '#be6a4d',
      instagramImage: 'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      sanDj: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      instagramTp:
        'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      bikeImg: 'https://imgix.cosmicjs.com/9562a300-47ef-11ef-a504-63e081e4680f-animated-bike.webp',
      logo: 'https://imgix.cosmicjs.com/9c4cf3d0-e0c6-11ef-bee4-3bb1d3c55332-tp-logo-white.png',

      mainImage:
        'https://imgix.cosmicjs.com/1098dee0-e8ea-11ef-b333-e101bec29f3d-atro-transparent.png'
    };
  },
  computed: {
    dynamicContent: {
      get() {
        return this.$store.state.dynamicContent;
      }
    },
    links: {
      get() {
        return this.$store.state.links || [];
      }
    },
    incomingParties: {
      get() {
        let parties = this.$store.state.calendar || [];

        function isTodayParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          let endOfDay = moment().endOf('day');
          return partyDateEndOfDay >= now && partyDateEndOfDay < endOfDay;
        }

        return [...parties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isTodayParty(x));
      }
    }
  }
};
</script>


